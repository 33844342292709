module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"greatestview","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"/opt/build/repo/src/images/icon.svg","theme_color_in_head":false,"legacy":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"3e2088afc5de4e0c92a98f6d24e5ac3b"},
    },{
      plugin: require('../plugins/gatsby-wordpress-inline-images/gatsby-browser.js'),
      options: {"plugins":[],"baseUrl":"wp.greatestview.de","protocol":"https","maxWidth":1280,"withWebp":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
